<template>
  <div class="discount-list">
    <div class="d-flex align-center flex-sm-nowrap flex-wrap">
      <div class="text-h5 font-weight-bold">Trip discount codes</div>
      <v-spacer></v-spacer>
      <v-card color="accent" flat tile class="ms-sm-3 d-flex align-center flex-wrap width100" max-width="520">
        <v-text-field
          class="field46 me-4 mt-sm-0 mt-2"
          v-model="search"
          placeholder="Search code"
          prepend-inner-icon="mdi-magnify"
          :append-icon="search ? 'mdi-close' : ''"
          @click:append="clearSearch"
          hide-details
          outlined
          dense
          required
          color="primary"
          height="46"
          @change="(page = 1), getData()"
        ></v-text-field>
        <v-btn to="/trip-discount-codes-new" depressed width="136" large class="primary mt-sm-0 mt-2">Create new</v-btn>
      </v-card>
    </div>
    <div class="mt-4">
      <v-card tile flat color="transparent" v-if="!data.result" class="d-flex justify-center align-center" :height="`calc(100vh - 240px)`">
        <div>
          <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
          <div class="text-center text--text mt-4">Sorry, but nothing found</div>
        </div>
      </v-card>
      <div v-else>
        <v-data-table
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="data.result"
          item-key="id"
          :items-per-page="-1"
          :height="`calc(100vh - 240px)`"
          class="d-sm-block d-none accent"
        >
          <template v-slot:header.available>
            Is available
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="btn-sort" :class="available != 'all' ? 'primary white--text' : ''">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :class="available == item.id ? 'primary--text font-weight-bold' : ''"
                  class="text-body-2"
                  link
                  v-for="item in itemsAvailable"
                  :key="item.id"
                  @click="(available = item.id), getData()"
                  style="min-height: 36px"
                >
                  {{ item.title }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-if="!data.pages" v-slot:no-data>
            <v-card tile flat color="transparent" class="d-flex justify-center align-center">
              <div>
                <v-img min-height="150" height="220" contain src="@/assets/img/bad.svg"></v-img>
                <div class="text-center text--text mt-4">Sorry, but nothing found</div>
              </div>
            </v-card>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" class="link" @click="openLink(item.id)">
                <td>{{ item.discount_code }}</td>
                <td>{{ item.company_name || '-' }}</td>
                <td>
                  {{ item.discount_type == 'fixed' ? 'SAR' : '' }}
                  {{ (item.discount_value / 100).toFixed(2) }}{{ item.discount_type == 'percent' ? '%' : '' }}
                </td>
                <td>
                  {{
                    item.available_since
                      ? new Date(item.available_since).toLocaleString('en-GB', { day: 'numeric', year: 'numeric', month: 'short' })
                      : ''
                  }}
                </td>
                <td>
                  {{
                    item.available_till
                      ? new Date(item.available_till).toLocaleString('en-GB', { day: 'numeric', year: 'numeric', month: 'short' })
                      : ''
                  }}
                </td>
                <td>{{ item.usage_count }}/{{ item.usage_count_limit }}</td>
                <td class="text-center">
                  <v-icon color="primary">{{ item.is_available ? 'mdi-check' : 'mdi-close' }}</v-icon>
                </td>
              </tr>
            </tbody>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-2 pb-2 px-3 d-flex align-center">
              <div class="text--text">Total {{ data.total }}</div>
              <v-spacer></v-spacer>
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template>
        </v-data-table>
        <div class="d-block d-sm-none">
          <div v-for="item in data.result" :key="item.id" @click="openLink(item.id)" class="px-4 pt-2">
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Code</div>
              <div class="font-weight-medium f18 text-truncate">{{ item.discount_code }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Company name</div>
              <div class="font-weight-medium f18">{{ item.company_name || '-' }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Discount</div>
              <div class="font-weight-medium f18">
                {{ item.discount_type == 'fixed' ? 'SAR' : '' }}
                {{ (item.discount_value / 100).toFixed(2) }}{{ item.discount_type == 'percent' ? '%' : '' }}
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Available from</div>
              <div class="font-weight-medium f18">
                {{
                  item.available_since
                    ? new Date(item.available_since).toLocaleString('en-GB', { day: 'numeric', year: 'numeric', month: 'short' })
                    : ''
                }}
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Available till</div>
              <div class="font-weight-medium f18">
                {{
                  item.available_till
                    ? new Date(item.available_till).toLocaleString('en-GB', { day: 'numeric', year: 'numeric', month: 'short' })
                    : ''
                }}
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Usage count/limit</div>
              <div class="font-weight-medium f18">{{ item.usage_count }}/{{ item.usage_count_limit }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Is available</div>
              <v-icon color="primary">{{ item.is_available ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
            <div class="text--text">Total {{ data.total }}</div>
            <v-spacer></v-spacer>
            <div class="ms-2">
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      search: this.$route.query.src || '',
      name: this.$route.query.group || 'all',
      available: this.$route.query.available || 'all',
      page: Number(this.$route.query.page) || 1,
      headers: [
        { text: 'Code', value: 'discount_code', sortable: false },
        { text: 'Company name', value: 'company_name', sortable: false },
        { text: 'Discount', value: 'discount_value', sortable: false },
        { text: 'Available from', value: 'available_since', sortable: false },
        { text: 'Available till', value: 'available_till', sortable: false },
        { text: 'Usage count/limit', value: 'usage_count', sortable: false },
        { text: 'Is available', value: 'available', sortable: false, align: 'center' },
      ],
      itemsAvailable: [
        { id: 'all', title: 'All' },
        { id: 'true', title: 'Is available' },
        { id: 'false', title: 'Is unavailable' },
      ],
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openLink(id) {
      this.$router.push(`/trip-discount-codes/${id}`);
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getDiscountCodesList', {
          search: this.search,
          available: this.available,
          page: this.page,
        })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.discountCodesList;
    },
  },
  destroyed() {
    this.$store.dispatch('setDiscountCodesList', {});
  },
};
</script>

<style lang="scss">
.discount-list {
  .btn-sort {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    .v-icon {
      font-size: 16px;
    }
  }
  table tbody tr:hover td:first-of-type {
    color: var(--v-primary-base) !important;
  }
}
</style>
